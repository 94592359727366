
import Button from "@/components/Button.vue";
import Layout from "@/Layout/Layout.vue";
import axios from "@/axios";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Layout, Button },
  data() {
    return {
      countdown: false,
      loading: false,
      formIsValid: false,
      alert: false,
      error: false,
      agree: false,
      message: "",
      cooperative_name: "",
      description: "",
      year_of_establishment: "",
      city_of_operation: "",
      primary_objective: "",
      address: "",
      state: "",
      country: "",
      no_of_members: "",
      loan_capacity: "",
      rep_title: "",
      rep_gender: "male",
      rep_fullname: "",
      rep_email: "",
      phonenumber: "",
      rep_address: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    countHandler() {
      this.countdown = !this.countdown;
    },
    signup() {
      this.alert = false;
      this.message = "";

      axios
        .post("/cooperative/onboard-cooperative", {
          cooperative_name: this.cooperative_name,
          description: this.description,
          year_of_establishment: this.year_of_establishment,
          city_of_operation: this.city_of_operation,
          primary_objective: this.primary_objective,
          address: this.address,
          state: this.state,
          country: this.country,
          no_of_members: this.no_of_members,
          loan_capacity: this.loan_capacity,
          rep_title: this.rep_title,
          rep_gender: this.rep_gender,
          rep_fullname: this.rep_fullname,
          rep_email: this.rep_email,
          phonenumber: this.phonenumber,
          rep_address: this.rep_address,
        })
        .then(() => {
          this.message =
            "You have successfully signup, we'll send a notification to you soon";
          this.error = false;
        })
        .catch((err) => {
          this.message = err.response.data.message;
          this.error = true;
        })
        .finally(() => {
          this.alert = true;

          setTimeout(() => {
            this.alert = false;
          }, 5000);
        });
    },
  },
});
